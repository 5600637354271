<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <AppPageHeader title="Liquidar nota comercial" subtitle="Visualize aqui o detalhamento da operação selecionada">
        <template #header-left>
          <div class="d-flex justify-content-end">
            <b-button size="sm" variant="outline-primary" @click="() => $router.go(-1)">
              Voltar
            </b-button>
          </div>
        </template>
      </AppPageHeader>
    </b-card>
    <b-row>
      <b-col sm="12">
        <b-card no-body class="d-flex flex-column align-items-start p-2">
          <div class="d-flex flex-column align-items-start p-0 w-100">
            <b-col>
              <h4 class="text-dark">
                Pagamento da Nota Comercial
              </h4>
              <h6>
                Informe a data do pagamento da Nota Comercial para atualizar o valor
              </h6>
            </b-col>
          </div>
          <div class="d-flex flex-column align-items-start p-1 w-100">
            <b-alert v-if="isOverdue" show variant="danger" class="p-1 border-danger w-100 text-dark">
              <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1 text-dark" />
              <b class="text-dark">Nota comercial vencida.</b> <span class="text-dark">Realize o pagamento com os valores atualizados abaixo.</span>
            </b-alert>

            <b-alert v-if="isDisputed" show variant="danger" class="p-1 border-danger w-100 text-dark">
              <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1 text-dark" />
              <b class="text-dark">Nota comercial protestada.</b> <span class="text-dark">Entre em contato com nosso
                departamento financeiro para regularizar sua situação</span>
            </b-alert>
          </div>

          <div class="d-flex flex-row justify-content-between align-items-start p-0 w-100">
            <div class="d-flex flex-column align-items-start p-0">
              <div class="d-flex flex-row align-items-center p-0">
                <b-col>
                  <b-form-group label="Valor a pagar atualizado" label-for="taxa-escrituracao" label-class="text-dark font-weight-bold" label-size="md">
                    <b-form-input id="taxa-escrituracao"
                                  v-model="comercialNote.encargos_por_atraso"
                                  name="taxa-escrituracao"
                                  disabled
                    />
                  </b-form-group>
                </b-col>
              </div>
              <div class="d-flex flex-row align-items-center p-0">
                <div v-if="isOverdue" class="d-flex flex-row align-items-start p-0 ml-1 mt-1">
                  <small>Total de encargos por atraso</small>
                  <h6>&nbsp;{{ comercialNote.total_de_encargos }}&nbsp;</h6>
                  <feather-icon icon="ArrowRightIcon" variant="primary" />&nbsp;
                  <small>
                    A multa diaria {{ comercialNote.multa_diaria }} ({{
                      comercialNote.multa_atraso
                    }}
                    sobre o valor a pagar + Mora de {{ comercialNote.mora_atraso }} a.m.)
                  </small>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center py-1 px-2">
              <b-card class="d-flex flex-column align-items-center justify-content-end padlocks-bg">
                <div class="d-flex flex-row align-items-center p-0">
                  <div class="d-flex flex-row justify-content-between">
                    <b-img src="@/assets/images/dashboard/whatsup.svg" />
                    <div class="d-flex flex-column justify-content-center align-items-center ml-2 mr-2">
                      <h4>Precisa de ajuda?</h4>
                      <span>Chame no WhatsApp</span>
                    </div>
                  </div>
                  <b-button target="_blank"
                            href="https://wa.me/551130343417"
                            size="sm"
                            variant="outline-primary"
                  >Chamar</b-button>
                </div>
              </b-card>
            </div>
          </div>
          <div class="d-flex flex-column align-items-start px-1 py-0 w-100">
            <liquidation-card class="d-flex w-100" :comercial-note="comercialNote" />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div class="d-flex flex-column align-items-start px-1 p-0">
            <div class="d-flex flex-column align-items-start p-0">
              <h4 class="text-dark">
                Dados para pagamento
              </h4>
              <h6>Use os dados abaixo para realizar o pagamento</h6>
            </div>
            <div class="d-flex flex-row align-items-start p-0 mt-1">
              <b-card class="d-flex flex-column align-items-start justify-content-between padlocks-bg px-1">
                <div class="d-flex flex-column align-items-start p-0 border-bottom pb-1">
                  <h4 class="text-dark">
                    Pagamento por transferência bancária
                  </h4>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-start pt-1 p-0">
                  <div class="d-flex flex-row align-items-center p-0">
                    <div class="d-flex flex-row align-items-start p-0">
                      <div class="d-flex flex-column align-items-start p-0 mr-1">
                        <span>Banco</span>
                        <h5 class="text-dark">
                          {{ comercialNote.fundo?.banco.nome }}
                        </h5>
                      </div>
                      <div class="d-flex flex-column align-items-start p-0 mr-1">
                        <span>Código</span>
                        <h5 class="text-dark">
                          {{ comercialNote.fundo?.banco.codigo }}
                        </h5>
                      </div>
                      <div class="d-flex flex-column align-items-start p-0 mr-1">
                        <span>Agência</span>
                        <h5 class="text-dark">
                          {{ comercialNote.fundo?.banco_agencia }}
                        </h5>
                      </div>
                      <div class="d-flex flex-column align-items-start p-0">
                        <span>Conta Corrente</span>
                        <h5 class="text-dark">
                          {{ comercialNote.fundo?.banco_conta }}-{{ comercialNote.fundo?.banco_conta_digito }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center p-0">
                    <div class="d-flex flex-row align-items-start p-0">
                      <div class="d-flex flex-column align-items-start p-0 mr-1">
                        <span>CNPJ</span>
                        <h5 class="text-dark">
                          {{ comercialNote.fundo?.cnpj }}
                        </h5>
                      </div>
                      <div class="d-flex flex-column align-items-start p-0">
                        <span>Empresa</span>
                        <h5 class="text-dark">
                          {{ comercialNote.fundo?.label }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
              <!--
                removendo conforme pedido
              <b-card class="d-flex flex-column align-items-start justify-content-between padlocks-bg px-2 ml-2">
                <div class="d-flex flex-column align-items-start p-0 border-bottom pb-1">
                  <h4>Pagamento por PIX</h4>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-start pt-1 p-0">
                  <div class="d-flex flex-row align-items-center p-0">
                    <div class="d-flex flex-row align-items-start p-0">
                      <b-img src="@/assets/images/dashboard/codigo_qr.svg" fluid />
                      <div class="d-flex flex-column align-items-start p-0 ml-2">
                        <div class="d-flex flex-column align-items-start p-0">
                          <span>Chave PIX</span>
                          <h5>89578919000170</h5>
                        </div>
                        <div class="d-flex flex-column align-items-start p-0">
                          <span>Empresa</span>
                          <h5>CreditCorp</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            -->
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppPageHeader from '@core/components/app-page-header/AppPageHeader.vue'
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BImg,
  BAlert,
} from 'bootstrap-vue'
import LiquidationCard from '@/views/admin/concessions/components/liquidation/LiquidationCard.vue'
import {
  moneyFormat,
  comercialNoteStatusBadge,
  liquidationStatusBadge,
  prazoFormatter,
  percentageFormatter,
  stringToDateFormatter,
  cnpjFormatter,
  dateFormatter,
} from '@/@core/comp-functions/data_visualization/datatable'

export default {
  name: 'LiquidationPage',
  components: {
    AppPageHeader,
    LiquidationCard,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BImg,
    BAlert,
  },
  filters: {
    comercialNoteStatusBadge,
    prazoFormatter,
    cnpjFormatter,
  },
  data() {
    return {
      comercialNote: {},
      paymentStatus: '',
      comercialNoteStatus: '',
    }
  },
  computed: {
    isOverdue() {
      return this.paymentStatus === 'Vencida'
    },
    isDisputed() {
      if (this.paymentStatus !== 'Vencida') return false

      const dataVencimento = stringToDateFormatter(this.comercialNote.data_vencimento)
      const hoje = new Date()
      dataVencimento.setDate(dataVencimento.getDate() + 7)
      return hoje.getTime() >= dataVencimento.getTime()
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      const { data } = await this.$store.dispatch(
        'concession/fetch',
        this.$route.params.id,
      )
      data.data_emissao = dateFormatter(data.data_emissao)
      data.data_vencimento = dateFormatter(data.data_vencimento)
      data.taxa_juros = percentageFormatter(data.taxa_juros)
      data.taxa_escrituracao = percentageFormatter(data.taxa_escrituracao)
      this.paymentStatus = data.quitacao
      this.comercialNoteStatus = data.status
      data.status = comercialNoteStatusBadge(data.status)
      data.quitacao = liquidationStatusBadge(data.quitacao)
      data.total_de_encargos = moneyFormat(data.encargos_por_atraso - data.valor_devido)
      data.valor_devido = moneyFormat(data.valor_devido)
      data.valor_emissao = moneyFormat(data.valor_emissao)
      data.encargos_por_atraso = moneyFormat(data.encargos_por_atraso)
      data.multa_diaria = moneyFormat(data.multa_diaria)
      data.multa_atraso = `${data.multa_atraso.replace('.', ',')}%`
      data.mora_atraso = `${data.mora_atraso.replace('.', ',')}%`
      data.empresa.prazo = prazoFormatter(data.empresa.prazo)
      data.fundo.cnpj = cnpjFormatter(data.fundo.cnpj)
      this.comercialNote = data
    },
  },
}
</script>

<style lang="scss">
.padlocks-bg {
  background-color: #f8fafc;
}
</style>
