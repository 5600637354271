import { render, staticRenderFns } from "./LiquidationCard.vue?vue&type=template&id=473ed37f&scoped=true&"
import script from "./LiquidationCard.vue?vue&type=script&lang=js&"
export * from "./LiquidationCard.vue?vue&type=script&lang=js&"
import style0 from "./LiquidationCard.vue?vue&type=style&index=0&id=473ed37f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473ed37f",
  null
  
)

export default component.exports