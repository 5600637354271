<template>
  <b-card class="cc-bg-light">
    <b-row>
      <b-col cols="12" class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center">
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <h6 class="text-dark d-flex w-100">
              CNPJ
            </h6>
            <span class="d-flex w-100">{{ comercialNote.empresa?.cnpj }}</span>
          </div>
          <div class="d-flex flex-column align-items-start gap-1 w-100 text-nowrap">
            <h6 class="text-dark d-flex w-100">
              Empresa
            </h6>
            <span class="d-flex w-100">{{ comercialNote.empresa?.nome_fantasia }}</span>
          </div>
          <div class="vl ml-2 mr-2" />
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <span class="d-flex w-100">Valor contratado</span>
            <span class="text-dark font-weight-bold d-flex w-100">{{ comercialNote.valor_emissao }}</span>
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <span class="d-flex w-100">Valor a pagar</span>
            <span class="text-dark font-weight-bold d-flex w-100">{{ comercialNote.valor_devido }}</span>
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <span class="d-flex w-100">Taxa a.m</span>
            <span class="text-dark font-weight-bold d-flex w-100">{{ comercialNote.taxa_juros }}</span>
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <span class="d-flex w-100">Taxa de Escrituração</span>
            <span class="text-dark font-weight-bold d-flex w-100">{{ comercialNote.taxa_escrituracao }}</span>
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <span class="d-flex w-100">Resgate</span>
            <span class="text-dark font-weight-bold d-flex w-100">{{ comercialNote.data_emissao }}</span>
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <span class="d-flex w-100">Vencimento</span>
            <span class="text-dark font-weight-bold d-flex w-100">{{ comercialNote.data_vencimento }}</span>
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100 text-nowrap">
            <span class="d-flex w-100">Prazo</span>
            <span class="text-dark font-weight-bold d-flex w-100">{{ comercialNote.empresa?.prazo || comercialNote.prazo }} </span>
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100">
            <span class="d-flex w-100">Status</span>
            <span class="d-flex w-100" v-html="comercialNote.status" />
          </div>
          <div class="d-flex flex-column align-items-start mr-2 gap-1 w-100">
            <span class="d-flex w-100">Quitação</span>
            <span class="d-flex w-100" v-html="comercialNote.quitacao" />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'LiquidationCard',
  components: { BRow, BCol, BCard },
  props: {
    comercialNote: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped lang="scss">
.gap-1 {
  gap: 4px;
}
</style>
